import { createApp } from 'vue';
import { createStore } from 'vuex';
import App from './App.vue';
import router from './router/router';
import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import VueGtag from 'vue-gtag';
import './style/global.scss';
import apiService from '@/services/ApiService';
import { VDateInput } from 'vuetify/labs/VDateInput';

const vuetify = createVuetify({
    components: {
        ...components,
        VDateInput,
    },
    directives,
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi,
        },
    },
    theme: {
        colors: {
            primary: '#FF7043',
        },
    },
    locale: {
        locale: 'it-IT',
    },
});

const store = createStore({
    state() {
        return {
            isLoading: false,
            isDarkTheme: true,
            authToken: null,
            googleTagEnabled: localStorage.getItem('cookiesAccepted') === 'all',
            showCookieBanner: !localStorage.getItem('cookiesAccepted')
        };
    },
    mutations: {
        SET_LOADING(state, payload) {
            state.isLoading = payload;
        },
        SET_AUTH_TOKEN(state, payload) {
            state.authToken = payload;
        },
        SET_THEME(state, payload) {
            state.isDarkTheme = payload;
        },
        SET_GOOGLE_TAG(state, payload) {
            state.googleTagEnabled = payload;
        },
        SET_SHOW_COOKIE_BANNER(state, payload) {
            state.showCookieBanner = payload;
        }
    },
    actions: {
        setLoading({ commit }, isLoading) {
            commit('SET_LOADING', isLoading);
        },
        setAuthToken({ commit }, authToken) {
            commit('SET_AUTH_TOKEN', authToken);
        },
        setTheme({ commit }, isDarkTheme) {
            if (isDarkTheme) {
                document.documentElement.style.setProperty('--background-color', '#312d2c');
                document.documentElement.style.setProperty('--text-color', '#fdfdfc');
                document.documentElement.style.setProperty('--secondary-background-color', '#464242');
            } else {
                document.documentElement.style.setProperty('--background-color', '#F3F4F6');
                document.documentElement.style.setProperty('--text-color', '#494751');
                document.documentElement.style.setProperty('--secondary-background-color', '#FFF');
            }
            localStorage.setItem('isDarkTheme', isDarkTheme);
            commit('SET_THEME', isDarkTheme);
        },
        setGoogleTag({ commit }, enabled) {
            commit('SET_GOOGLE_TAG', enabled);
        },
        async validateToken({ commit }) {
            try {
                const token = await apiService.validateToken();
                commit('SET_AUTH_TOKEN', token);
            } catch (e) {
                commit('SET_AUTH_TOKEN', null);
            }
        },
        setShowCookieBanner({ commit }, show) {
            commit('SET_SHOW_COOKIE_BANNER', show);
        }
    },
});

let app = createApp(App)
    .use(store)
    .use(vuetify)
    .use(router)

if(store.state.googleTagEnabled){
    app = app.use(VueGtag, {
        config: { id: 'G-ERWHLL630J' },
        enabled: store.state.googleTagEnabled,
    });
} else {
    const cookies = document.cookie.split(';');
    cookies.forEach(cookie => {
        const cookieName = cookie.split('=')[0].trim();
        if (cookieName.startsWith('_ga')) {
            document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        }
    });
}

app.mount('#app');