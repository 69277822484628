<template>
  <div class="categories-container">
    <div class="title-box primary-box">
      <h2>{{ getTranslation(language, "articles") }}</h2>
      <div class="search-container">
        <search-article-autocomplete
            :label="getTranslation(language, 'search-article')"
            variant="outlined"
        >
        </search-article-autocomplete>
      </div>
    </div>
    
    <div class="category-container">
      <h2>{{ getTranslation(language, "article-categories-title") }}</h2>
<!--      <div class="card-deck">-->
<!--        <v-card-->
<!--            v-for="category in categories"-->
<!--            :key="category.id"-->
<!--            class="card"-->
<!--            itemscope itemtype="https://schema.org/NewsArticle"-->
<!--        >-->
<!--          <router-link :to="`/article-category/${category.id}/${category.slug.get(language)}`" itemprop="url">-->
<!--            <v-img src="/img/newsstand.svg" :alt="category.title" height="200px"></v-img>-->
<!--            <div class="card-description">-->
<!--              <v-card-title itemprop="headline">{{ category?.title?.get(language) }}</v-card-title>-->
<!--              <v-card-subtitle>{{ category?.subtitle?.get(language) }}</v-card-subtitle>-->
<!--            </div>-->
<!--            <p style="display: none" itemprop="author" itemtype="https://schema.org/Person">-->
<!--              <span itemprop="name">Cinopedia.cloud</span>-->
<!--            </p>-->
<!--            <p style="display: none" itemprop="image">/img/newsstand.svg</p>-->
<!--          </router-link>-->
<!--        </v-card>-->
<!--      </div>-->
      <v-expansion-panels variant="popout" class="my-4">
        <v-expansion-panel
            v-for="category in categories"
            :key="category.id"
            :title="category.title.get(language)"
        >
          <v-expansion-panel-text>
            <p class="category-subtitle">{{ category.subtitle?.get(language) }}</p>
            <router-link :to="`/article-category/${category.id}/${category.slug.get(language)}`" class="read-more-link">
              {{ getTranslation(language, "article-read-more") }}
            </router-link>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>
<script>
import apiService from "@/services/ApiService";
import {getTranslation} from "@/services/stringTableService";
import SearchArticleAutocomplete from "@/components/SearchAarticleAutocomplete.vue";

export default {
  components: {SearchArticleAutocomplete},
  data() {
    return {
      categories: [],
      language: "IT"
    };
  },
  methods: {
    getTranslation,
    async fetchCategories() {
      try {
        this.$store.dispatch('setLoading', true);
        this.categories = await apiService.getArticleCategories();
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch('setLoading', false);
      }
    }
  },
  async created() {
    await this.fetchCategories();
  }
};
</script>
<style scoped lang="scss">
@import "../style/_variables.scss";

.category-subtitle {
  margin-bottom: 10px;
}
.read-more-link {
  color: $primary-color;
  text-decoration: underline;
  font-weight: bold;
  margin-top: 10px;
  display: inline-block;
}

.categories-container {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .category-container {
    margin-top: 30px;
  }
}
.search-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px 0;

  h2 {
    margin-bottom: 20px;
  }
}

h2, h3, h4, p {
  font-family: $header-font;
}
</style>