import { createRouter, createWebHistory } from 'vue-router'
import Home from '../components/Home.vue'
import BreedDetail from "../components/BreedDetail.vue";
import EnciGroupDetail from "@/components/EnciGroupDetail.vue";
import MotivationalGroupDetail from "@/components/MotivationalGroupDetail.vue";
import sidService from "@/services/sidService";
import ClickerPage from "@/components/ClickerPage.vue";
import MotivationalGroupList from "@/components/MotivationalGroupList.vue";
import EnciGroupList from "@/components/EnciGroupList.vue";
import BreedList from "@/components/BreedList.vue";
import LoginPage from "@/components/LoginPage.vue";
import AccessesPage from "@/components/AccessesPage.vue";
import ArticleCategoryList from "@/components/ArticleCategoryList.vue";
import ArticleCategoryDetail from "@/components/ArticleCategoryDetail.vue";
import ArticleDetail from "@/components/ArticleDetail.vue";
import CachePage from "@/components/CachePage.vue";

const routes = [
    { 
        path: '/', 
        component: Home,
        name: 'Home'
    },
    {
        path: '/breed/:id/:slug?',
        name: 'BreedDetail',
        component: BreedDetail,
        props: true
    },
    {
        path: '/breeds',
        name: 'BreedList',
        component: BreedList,
        props: true
    },
    {
        path: '/enci-group/:id/:slug?',
        name: 'EnciGroupDetail',
        component: EnciGroupDetail,
        props: true
    },
    {
        path: '/enci-groups',
        name: 'EnciGroupList',
        component: EnciGroupList,
        props: true
    },
    {
        path: '/motivational-classification/:id/:slug?',
        name: 'MotivationalGroupDetail',
        component: MotivationalGroupDetail,
        props: true
    },
    {
        path: '/motivational-classifications',
        name: 'MotivationalGroupList',
        component: MotivationalGroupList,
        props: true
    },
    {
        path: '/clicker',
        name: 'Clicker',
        component: ClickerPage,
        props: true
    },  
    {
        path: '/login',
        name: 'Login',
        component: LoginPage
    },
    {
        path: "/accesses",
        name: "Accesses",
        component: AccessesPage
    },
    {
        path: "/admin-cache",
        name: "CachePage",
        component: CachePage
    },
    {
        path: '/article-categories',
        name: 'ArticleCategoryList',
        component: ArticleCategoryList,
        props: true
    },
    {
        path: '/article-category/:id/:slug?',
        name: 'ArticleCategoryDetail',
        component: ArticleCategoryDetail,
        props: true
    },
    {
        path: '/article/:id/:slug?',
        name: 'ArticleDetail',
        component: ArticleDetail,
        props: true
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.afterEach((to, from) => {
    if(to.name === from.name) return
    sidService.logAccess({ path: to.path })
    document.getElementById("app").scrollIntoView()
})

export default router