<template>
  <div class="motivational-group-detail-container">
    <div class="primary-box title-box" itemscope itemtype="https://schema.org/NewsArticle">
      <h1 style="text-transform: uppercase" itemprop="headline">{{ group.name?.get(language) ?? "" }} ({{group.breeds?.length ?? ""}})</h1>
      <p v-if="!!group.description?.get(language)">
        {{ group.description?.get(language)}}
      </p>
      <p style="display: none" itemprop="image">/img/paw-print-icon.svg</p>
      <p style="display: none" itemprop="author" itemtype="https://schema.org/Person">
        <span itemprop="name">Cinopedia.cloud</span>
      </p>
    </div>
    <v-expansion-panels variant="popout" class="my-4">
      <v-expansion-panel 
          :title="getTranslation(language, 'motivations')" 
      >
        <v-expansion-panel-text>
          <div class="motivations-container">
            <div>
              <h2>{{getTranslation(language, "high")}}</h2>
              <MotivationItem
                  v-for="motivation in group.orderedMotivations.filter(x => x.level === 'high')"
                  :key="motivation.id"
                  :language="language"
                  :motivation="motivation"
              />
            </div>
            <div>
              <h2>{{getTranslation(language, "medium")}}</h2>
              <MotivationItem
                  v-for="motivation in group.orderedMotivations.filter(x => x.level === 'medium')"
                  :key="motivation.id"
                  :language="language"
                  :motivation="motivation"
              />
            </div>
            <div>
              <h2>{{getTranslation(language, "low")}}</h2>
              <MotivationItem
                  v-for="motivation in group.orderedMotivations.filter(x => x.level === 'low')"
                  :key="motivation.id"
                  :language="language"
                  :motivation="motivation"
              />
            </div>
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel 
          :title="getTranslation(language, 'emotional-asset-and-arousal')"
      >
        <v-expansion-panel-text v-if="group.arousalLevel && group.emotionalAsset?.length > 0">
          <div v-if="group.arousalLevel">
            <h2>{{getTranslation(language, "arousal")}}</h2>
            <p>{{group.arousalLevel?.name?.get(language)}}</p>
          </div>
          <div v-if="group.emotionalAsset?.length > 0">
            <h2>{{getTranslation(language, "emotional-asset")}}</h2>
            <p>{{group.emotionalAsset?.map(x => x.name?.get(language)).join(' / ')}}</p>
          </div>
        </v-expansion-panel-text>
        <v-expansion-panel-text v-else>
          <h2>{{getTranslation(language, "to-be-defined")}}</h2>
          <p>{{getTranslation(language, "if-you-have-it")}} <a href="mailto:cinopedia@fiveamtech.it">cinopedia@fiveamtech.it</a></p>
        </v-expansion-panel-text>
      </v-expansion-panel>
      
<!--      <v-expansion-panel :title="getTranslation(language, 'breed-list')">-->
<!-- -->
<!--        <v-expansion-panel-text v-else>-->
<!--          <h2>{{getTranslation(language, "to-be-defined")}}</h2>-->
<!--        </v-expansion-panel-text>-->
<!--      </v-expansion-panel>-->
    </v-expansion-panels>

    <h2>{{getTranslation(language, "breed-list")}}</h2>
    <div v-if="group.breeds?.length > 0">
<!--      <v-list v-if="!$vuetify.display.mdAndUp" class="list-card">-->
<!--        <v-list-item-->
<!--            v-for="breed in group.breeds"-->
<!--            :key="breed.id"-->
<!--            class="list-item"-->
<!--            @click="navigateToBreedDetail(breed.id)"-->
<!--        >-->
<!--          <template v-slot:append>-->
<!--            <img :src="breed.imageUrl" :alt="breed.name?.get(language)" width="50">-->
<!--          </template>-->
<!--          <v-list-item-title>{{ breed.name?.get(language)}}</v-list-item-title>-->
<!--          <v-list-item-subtitle>{{ breed.enciSection?.get(language)}}</v-list-item-subtitle>-->
<!--        </v-list-item>-->
<!--      </v-list>-->
      <div class="card-deck">
        <v-card 
            class="card" 
            v-for="breed in group.breeds" 
            :key="breed.id"
            itemscope itemtype="https://schema.org/NewsArticle"
        >
          <router-link :to="`/breed/${breed.id}`" itemprop="url">
            <v-img :src="breed.imageUrl" :alt="breed.name" height="200px"></v-img>
            <p style="display: none" itemprop="image">{{breed.imageUrl}}</p>
            <div class="card-description">
              <v-card-title itemprop="headline">{{ breed.name?.get(language) }}</v-card-title>
              <v-card-subtitle>{{ breed.enciSection?.get(language) }}</v-card-subtitle>
            </div>
            <p style="display: none" itemprop="author" itemtype="https://schema.org/Person">
              <span itemprop="name">Cinopedia.cloud</span>
            </p>
          </router-link>
        </v-card>
      </div>
    </div>
    <div v-else>
      <h2>{{getTranslation(language, "to-be-defined")}}</h2>
      <p>{{getTranslation(language, "if-you-have-it")}} <a href="mailto:cinopedia@fiveamtech.it">cinopedia@fiveamtech.it</a></p>
      
    </div>
  </div>
</template>

<script>
import apiService from "@/services/ApiService";
import {getTranslation} from "@/services/stringTableService";
import MotivationItem from "@/components/MotivationItem.vue";
import {updateMetaInfo} from "@/middleware/metaUpdater";

export default {
  components: {MotivationItem},
  methods: {
    getTranslation,
    navigateToBreedDetail(id) {
      this.$router.push({ name: "BreedDetail", params: { id } });
    },
    async fetchGroup() {
      const groupId = this.$route.params.id;
      try {
        this.$store.dispatch('setLoading', true);
        const group = await apiService.getMotivationalGroup(groupId);
        this.group = group;
        
        console.log(group)

        this.updateUrlWithSlug(group);
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch('setLoading', false);
      }
    },
    updateUrlWithSlug(data) {
      this.$router.replace({
        name: 'MotivationalGroupDetail',
        params: { id: data.id, slug: `GM-${data.externalId}` },
      });
    }
  },
  watch: {
    $route(to, from) {
      if(to.params.id !== from.params.id)
        this.fetchGroup();
    },
    group(){
      updateMetaInfo(this.metaInfo);
    }
  },
  data() {
    return {
      group: {},
      sections: [],
      selectedSection: "",
      headers: [
        { text: getTranslation("IT", "image"), value: "imageUrl" },
        { text: getTranslation("IT", "section"), value: "enciSection" },
        { text: getTranslation("IT", "name"), value: "name" }
      ],
      language: "IT"
    };
  },
  async created() {
    await this.fetchGroup();
  },
  computed: {
    metaInfo() {
      const groupDescription = this.group?.description?.get(this.language) ?? "";
      const groupName = this.group?.name?.get(this.language) ?? "";
      return {
        title: groupName + " - Cinopedia.cloud",
        description: groupDescription,
        keywords: "razze, fci, enci, cani, cinofilia, " + groupDescription + ", " + groupName
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../style/_variables.scss";

.motivational-group-detail-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  h1, h2 {
    font-family: $header-font;
    font-weight: bolder;
  } 
  
  .motivations-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    h2 {
      font-family: $header-font;
      font-weight: bolder;
    }
  }
  .list-card {
    margin-top: 10px;
    overflow-y: auto;
  }
  .list-item {
    text-align: left;
    max-width: 90vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    cursor: pointer;
    :hover {
      color: $primary-color;
    }
    .v-list-item-title, .v-list-item-subtitle {
      text-overflow: unset !important;
      white-space: normal !important;
    }
  }
}
</style>