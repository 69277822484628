import it from '../assets/lang/it.json';

const languages = {it}

export function getStringTable(lang) {
    return languages[lang.toLowerCase()] ?? {};
}

export function getTranslation(lang, key, params = null) {
    const value = getStringTable(lang.toLowerCase())[key] ?? key;
    
    if (params) {
        return value.replace(/{(\d+)}/g, (match, number) => {
            return typeof params[number] != 'undefined' ? params[number] : match;
        });
    }
    return value;
}