import LocalString from "@/entities/LocalString";
import Article from "@/entities/Article";

export default class ArticleCategory {
    constructor(data) {
        this.id = data.id;
        this.title = new LocalString(data.title);
        this.subtitle = new LocalString(data.subtitle);
        this.slug = new LocalString(data.slug);
        
        this.articles = data.articles 
            ? data.articles.map(x => new Article(x))
            : [];
    }
}