import {UAParser} from 'ua-parser-js';


function getElementViewed(path, breeds, enciGroups, motivationalGroups) {
    let elementViewed = path;
    const language = 'IT';

    if(!elementViewed)
        return 'Not specified';
    
    
    if(elementViewed.includes('/breed/')) {
        let breedId = elementViewed.split('/')[2];
        let breed = breeds.find(x => x.id === breedId);
        if(breed){
            elementViewed = "RZ - " + breed.name.get(language);
        }
    } else if(elementViewed.includes('/motivational-classification/')) {
        let motivationalGroupId = elementViewed.split('/')[2];
        let motivationalGroup = motivationalGroups.find(x => x.id === motivationalGroupId);
        if(motivationalGroup){
            elementViewed = "CM - " + motivationalGroup.name.get(language);
        }
    } else if(elementViewed.includes('/enci-group/')) {
        let enciGroupId = elementViewed.split('/')[2];
        let enciGroup = enciGroups.find(x => x.id === enciGroupId);
        if(enciGroup){
            elementViewed = "ENCI - " + enciGroup.name.get(language) + " - " + enciGroup.description.get(language);
        }
    } else if (elementViewed === '/') {
        elementViewed = "Home";
    } else if (elementViewed === '/login') {
        elementViewed = "Login";
    }  else if (elementViewed === '/accesses') {
        elementViewed = "Accesses";
    } else if (elementViewed === '/breed' || elementViewed === '/breeds') {
        elementViewed = "Breed List";
    } else if (elementViewed === '/motivational-classification' || elementViewed === '/motivational-classifications') {
        elementViewed = "Motivational Classification List";
    } else if (elementViewed === '/enci-group' || elementViewed === '/enci-groups') {
        elementViewed = "ENCI Group List";
    }

    return elementViewed;
}

function getDeviceName(device) {
    const {vendor, model} = device;
    if(!vendor && !model)
        return 'Not specified';
    return [vendor, model].filter(x => x).join(' ');
}

export const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];


export default class AccessLog {
    constructor(data, breeds, enciGroups, motivationalGroups) {
        this.id = data.id;
        this.date = new Date(data.date);
        this.hour = this.date.getHours();
        this.day = this.date.getDate();
        this.month = this.date.getMonth() + 1;
        this.year = this.date.getFullYear();
        this.weekDay = this.date.getDay();
        this.sessionId = data.sessionId;
        this.ip = data.ip;
        this.country = data?.ipLocation?.country;
        this.regionName = data?.ipLocation?.regionName;
        this.city = data?.ipLocation?.city;
        this.location = `${this.country}, ${this.regionName}, ${this.city}`;
        this.isp = data?.ipLocation?.isp;
        this.userAgent = data.agent;
        this.path = data?.additionalData?.path;
        this.elementViewed = getElementViewed(this.path, breeds, enciGroups, motivationalGroups);

        const {browser, cpu, device, os} = UAParser(this.userAgent);
        this.deviceType = device?.type ?? 'Desktop';
        this.device = getDeviceName(device);
        this.browser = browser.name + " " + browser.version;
        this.cpu = cpu.architecture;
        this.os = os.name + " " + os.version;
    }
}