import Motivation from "@/entities/Motivation";
import LocalString from "@/entities/LocalString";
import Breed from "@/entities/Breed";

export class ArousalLevel { 
    constructor(level) {
        if(!level) return null;
        
        this.value = level;
    }    
    get name() {
        switch (this.value) {
            case 0:
                return new LocalString({"IT": "Basso"});
            case 1:
                return new LocalString({"IT": "Medio-Basso"});
            case 2:
                return new LocalString({"IT": "Medio"});
            case 3:
                return new LocalString({"IT": "Medio-Alto"});
            case 4:
                return new LocalString({"IT": "Alto"});
            default:
                return null;
        }
    }
}

export class EmotionalAsset {
    constructor(asset) {
        if(asset == null) return null;
        this.asset = asset;
    }    
    get name() {
        switch (this.asset) {
            case 0:
                return new LocalString({"IT": "Diffidente"});
            case 1:
                return new LocalString({"IT": "Timido"});
            case 2:
                return new LocalString({"IT": "Reattivo"});
            case 3:
                return new LocalString({"IT": "Aperto"});
            default:
                return null;
        }
    }
}

export default class MotivationalGroup {
    constructor(data) {
        this.id = data.id;
        this.externalId = data.externalId;
        this.name = new LocalString(data.name);
        this.description = new LocalString(data.description);
        
        this.arousalLevel = data.arousalLevel ? new ArousalLevel(data.arousalLevel) : null;
        this.emotionalAsset = data.emotionalAsset?.map(x => new EmotionalAsset(x)) ?? [];
        this.orderedMotivations = data.orderedMotivations
            ?.map((x, i) => new Motivation(x, i, data.orderedMotivations.length)) ?? [];
        
        this.breeds = data.breeds?.map(x => new Breed(x)) ?? [];
    }
}