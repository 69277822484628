export default class ArticleCacheRow {
    constructor(data) {
        this.id = data.id;
        this.title = data.title;
        this.subtitle = data.subtitle;
        this.abstract = data.abstract;
        this.slug = data.slug;
        this.categoryId = data.categoryId;
        this.categoryTitle = data.categoryTitle;
        this.categorySubtitle = data.categorySubtitle;
        this.categorySlug = data.categorySlug;
    }
}