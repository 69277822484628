<template>
  <div v-if="chartData && chartData.labels" class="chart-container">
    <line-chart :data="chartData" :options="chartOptions"></line-chart>
  </div>
</template>

<script>
import { Line } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement);

export default {
  name: 'AccessesChart',
  components: {
    LineChart: Line
  },
  props: {
    chartData: {
      type: Object,
      required: true
    },
    chartOptions: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>
.chart-container {
  width: 100%;
  min-width: 350px;
  margin-top: 20px;
  min-height: 350px;
}
</style>