import LocalString from "@/entities/LocalString";
import Breed from "@/entities/Breed";

export default class EnciGroup {
    constructor(data) {
        this.id = data.id;
        this.externalId = data.externalId;
        this.name = new LocalString(data.name);
        this.description = new LocalString(data.description);
        this.enciLink = data.enciLink;
        this.breeds = data.breeds?.map(x => new Breed(x)) ?? [];
    }
}