export const CacheType = {
    "0": "None",
    "1": "FastSearch",
    "2": "BreedList",
    "3": "EnciGroupList",
    "4": "MotivationalGroupList",
    "5": "ArticleCategoryList",
    "6": "ArticleSearch",
    "7": "Article",
    "8": "ArticleCategory",
    "9": "EnciGroup",
    "10": "MotivationalGroup",
    "11": "Breed",
    "12": "Sitemap",
}


export function formatSize(sizeInBytes) {
    return (sizeInBytes / 1024).toFixed(2) + ' KB';
}
export default class CacheFileInfo {
    constructor(data) {
        this.type = CacheType[data.type.toString()];
        this.fileName = data.fileName;
        this.lastUpdate = new Date(data.lastUpdate);
        this.fileSize = data.fileSize;
        this.formattedFileSize = formatSize(data.fileSize);
    }
}