<template>
  <div class="login-container" style="display: flex;flex-direction: column;align-items: center;justify-content: center;width: 100%;margin-top: 20px;padding: 20px;border-radius: 5px;">
    <form @submit.prevent="login" class="primary-box" style="padding: 20px">
      
      <div>
        <v-text-field
            class="login-input"
            style="min-width: 350px;"
          v-model="username"
          label="Username"
          required
        />
        <v-text-field
            class="login-input"
          v-model="password"
          label="Password"
          type="password"
          required
        />
      </div>
      <div class="login-button__container" style="display: flex;justify-content: center;">
        <v-btn type="submit" class="tertiary-bg-color">Login</v-btn>
      </div>
    </form>
  </div>
</template>

<script>

import apiService from "@/services/ApiService";

export default {
  data() {
    return {
      username: '',
      password: ''
    };
  },
  methods: {
    async login() {
      try {
        this.$store.dispatch('setLoading', true);
        const token = await apiService.login(this.username, this.password);
        this.$store.dispatch('setAuthToken', token);
        this.$router.push({ name: 'Home' });
      } catch (error) {
        console.error('Login failed', error);
      } finally {
        this.$store.dispatch('setLoading', false);
      }
    }
  }
};
</script>

<style scoped>
@import "../style/_variables.scss";

.login-container {
  .login-input {
    min-width: 350px;
  }
  .login-button__container {
    display: flex;
    justify-content: center;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  padding: 20px;
  
  border-radius: 5px;
  
  form {
    padding: 20px;
  }
}
</style>