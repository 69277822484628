<template>
  <div class="enci-group-detail-container">
    <div class="title-box primary-box">
      <h1>{{getTranslation(language, "enci-groups")}}</h1>
    </div>
<!--    <v-card class="list-card" density="comfortable" v-if="!$vuetify.display.mdAndUp">-->
<!--      <v-list>-->
<!--        <v-list-item-->
<!--            v-for="group in groups"-->
<!--            :key="group.id"-->
<!--            class="list-item"-->
<!--            @click="navigateToEnciGroupDetail(group.id)"-->
<!--        >-->
<!--          <template v-slot:prepend>-->
<!--              <img src="/img/dog-icon.svg" alt="ENCI/FCI" width="25">-->
<!--          </template>-->
<!--          <v-list-item-title>{{ group.name?.get(language)}}</v-list-item-title>-->
<!--          <v-list-item-subtitle>{{ group.description?.get(language)}}</v-list-item-subtitle>-->
<!--        </v-list-item>-->
<!--      </v-list>-->
<!--    </v-card>-->
    <div class="card-deck">
      <v-card 
          class="card" 
          v-for="group in groups" 
          :key="group.id"
          itemscope itemtype="https://schema.org/NewsArticle"
      >
        <router-link :to="`/enci-group/${group.id}/ENCI-${group.externalId}`">
          <v-img src="/img/dog-icon.svg" alt="ENCI/FCI" height="150px"></v-img>
          <p style="display: none" itemprop="image">/img/dog-icon.svg</p> 
             
          <div class="card-description">
            <v-card-title>{{ group.name?.get(language) }}</v-card-title>
            <v-card-subtitle itemprop="headline">{{ group.description?.get(language) }}</v-card-subtitle>
          </div>

          <p style="display: none" itemprop="author" itemtype="https://schema.org/Person">
            <span itemprop="name">Cinopedia.cloud</span>
          </p>
        </router-link>
      </v-card>
    </div>
  </div>
</template>

<script>
import apiService from "@/services/ApiService";
import {getTranslation} from "@/services/stringTableService";
import {updateMetaInfo} from "@/middleware/metaUpdater";

export default {
  methods: {
    getTranslation,
    async fetchGroupList() {
      try {
        this.$store.dispatch('setLoading', true);
        const groups = await apiService.getEnciGroupList();
        this.groups = groups.sort((a, b) => {
          const nameA = parseInt(a.externalId);
          const nameB = parseInt(b.externalId);
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch('setLoading', false);
      }
    }
  },
  data() {
    return {
      groups: {},
      language: "IT"
    };
  },
  async created() {
    await this.fetchGroupList();
    
    updateMetaInfo({
      title: "Lista gruppi ENCI - Cinopedia.cloud",
      description: "Lista gruppi ENCI - Cinopedia.cloud",
      author: "Cinopedia.cloud",
      robots: "index, follow",
      keywords: "razze, fci, enci, cani, cinofilia"
    })
  }
};
</script>

<style scoped lang="scss">
@import "../style/_variables.scss";

.enci-group-detail-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  h1, h2 {
    font-family: $header-font;
    font-weight: bolder;
  }
  .section-select {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 350px;
  }
  .list-card {
    margin-top: 10px;
    overflow-y: auto;
    max-height: 70vh;
  }
  .list-item {
    text-align: left;
    max-width: 90vw;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    :hover {
      color: $primary-color;
    }
    .v-list-item-title, .v-list-item-subtitle {
      text-overflow: unset !important;
      white-space: normal !important;
    }
  }
}
</style>