<template>
  <div class="cache-container">
    <div class="title-box primary-box">
      <h1>{{getTranslation('cache-page')}}</h1>
    </div>
    <div class="data-container">
      <div v-if="isMobile">
        <v-expansion-panels>
          <v-expansion-panel v-for="cache in cacheInfo" :key="cache.type">
            <v-expansion-panel-title>
              <div class="cache-group-panel-text">
                <div class="cache-group-panel-text__data">
                  <div>
                    <h2>{{cache.type}} ({{cache.filesCount}})</h2>
                  </div>
                  <div>
                    <h3>{{cache.formattedTotalSize}}</h3>
                  </div>
                  <div>
                    <h3>{{formatDate(cache.lastUpdate)}}</h3>
                  </div>
                </div>
                <div class="cache-group-panel-text__actions">
                  <v-btn @click="updateCache(cache.typeId)">
                    <v-icon size="large">
                      mdi-update
                    </v-icon>                    
                  </v-btn>
                </div>
              </div>                
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div class="cache-files-container">
                <div class="cache-file-container" v-for="file in cache.filesInfo" :key="file.fileName">
                  <div class="cache-group-panel-text border-bottom">
                    <div class="cache-group-panel-text__data">
                      <div>
                        <h3>{{file.fileName}}</h3>
                      </div>
                      <div>
                        <h4>{{file.formattedFileSize}}</h4>
                      </div>
                      <div>
                        <h4>{{formatDate(file.lastUpdate)}}</h4>
                      </div>
                    </div>
                    <div class="cache-group-panel-text__actions">
                      <v-btn @click="downloadCache(file.fileName)">
                        <v-icon size="large">
                          mdi-download
                        </v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div v-else>
        <v-data-table
            :headers="[
              { title: 'Type', value: 'type', sortable: true },
              { title: 'Count', value: 'filesCount', sortable: true },
              { title: 'Size', value: 'totalSize', sortable: true },
              { title: 'Last Upd', value: 'lastUpdate', sortable: true },
              { title: 'Actions', value: 'actions'}
            ]"
            :items="cacheInfo"
            :items-per-page="99999"
            hide-default-footer
            show-expand
            v-model:expanded="expanded"
            density="compact"
            class="elevation-1"
            item-value="type"
        
        >
          <template v-slot:[`item.totalSize`]="{ item }">
            {{ item.formattedTotalSize }}
          </template>
          <template v-slot:[`item.lastUpdate`]="{ item }">
            {{ formatDate(item.lastUpdate) }}
          </template>
          <template v-slot:expanded-row="{item, columns}">
            <tr>
              <td :colspan="columns.length">
                <v-data-table
                    :headers="[
                  { title: 'Name', value: 'fileName', sortable: true },
                  { title: 'Size', value: 'fileSize', sortable: true },
                  { title: 'Last Update', value: 'lastUpdate', sortable: true },
                  { title: 'Actions', value: 'actions'}
                ]"
                    :items="item.filesInfo"
                    :items-per-page="99999"
                    hide-default-footer
                    density="compact"
                >
                  <template v-slot:[`item.fileSize`]="{ item }">
                    {{ item.formattedFileSize }}
                  </template>
                  <template v-slot:[`item.lastUpdate`]="{ item }">
                    {{ formatDate(item.lastUpdate) }}
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon
                        class="me-2"
                        size="small"
                        @click="downloadCache(item.fileName)"
                    >
                      mdi-download
                    </v-icon>
                  </template>

                </v-data-table>
              </td>
            </tr>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
                class="me-2"
                size="small"
                @click="updateCache(item.typeId)"
            >
              mdi-update
            </v-icon>
          </template>
          
        </v-data-table>
      </div>
    </div>
  </div>
</template>
<script>
import apiService from "@/services/ApiService";
import {getTranslation} from "@/services/stringTableService";

export default {
  name: 'CachePage',
  data() {
    return {
      cacheInfo: [],
      language: "IT",
      expanded: []
    }
  },
  methods: {
    getTranslation(value) {
      return getTranslation(this.language, value);
    },
    formatDate(date) {
      const isoString = new Date(date).toISOString();
      let dateString = isoString.substring(0, isoString.length - 5);
      dateString = dateString.replace("T", " ");
      return dateString;
    },
    async updateCache(type) {
      this.$store.dispatch('setLoading', true);
      try {
        await apiService.updateCache(type);
        await this.fetchCacheInfo();
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch('setLoading', false);
      }
      
    },
    async downloadCache(fileName) {
      this.$store.dispatch('setLoading', true);
      try {
        let filePath = `/cache/${fileName}`;
        if(fileName === 'sitemap.xml') {
          filePath = `/sitemap.xml`;
        }
        const link = document.createElement('a');
        link.href = filePath;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (e) {
        console.error(e)
      } finally {
        this.$store.dispatch('setLoading', false);
      }
    },
    async fetchCacheInfo() {
      this.$store.dispatch('setLoading', true);
      try {
        this.cacheInfo = await apiService.getCacheInfo();
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch('setLoading', false);
      }
    }
  },
  async created() {
    if (!this.$store.state.authToken) {
      this.$router.push({ name: 'Login' });
    } else {
      await this.fetchCacheInfo();
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.display.smAndDown;
    }
  }
  
}
</script>
<style scoped lang="scss">
@import "../style/_variables.scss";

.cache-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .data-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
    border-radius: 5px;
  }
  .border-bottom {
    border-bottom: 1px solid $primary-color;
  }
  .cache-group-panel-text {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 10px;
    flex-wrap: wrap;
    padding-bottom: 20px;
    .cache-group-panel-text__data {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-right: 5px;
      justify-content: space-between;
      flex-wrap: wrap;
      div {
        flex: 1;
      }
    }
    .cache-group-panel-text__actions {
      display: flex;
      justify-content: center;
    }    
  }
  .v-btn {
    color: $primary-contrast-color;
    background: $primary-gradient;
  }
  h2, h3, h4 {
    font-family: $header-font;
  }
}
</style>