import axios from 'axios';
import Breed from "@/entities/Breed";
import EnciGroup from "@/entities/EnciGroup";
import MotivationalGroup from "@/entities/MotivationalGroup";
import AccessLog from "@/entities/AccessLog";
import ArticleCategory from "@/entities/ArticleCategory";
import Article from "@/entities/Article";
import ArticleCacheRow from "@/entities/ArticleCacheRow";
import CacheFileGroupInfo from "@/entities/CacheFileGroupInfo";
class ApiService {
    constructor(baseUrl, useCache = false) {
        this.baseUrl = baseUrl;
        this.apiUrl = baseUrl + '/api/latest';
        this.useCache = useCache;
        this.token = null;
    }
    
    getToken() {
        return this.token ?? localStorage.getItem('token') ?? null;
    }
    
    updateToken(token) {
        if(token) {
            this.token = token;
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            localStorage.setItem('token', token);
        } else {
            this.token = null;
            delete axios.defaults.headers.common['Authorization'];
            localStorage.removeItem('token');
        }
    }
    
    async logAccess(id, additionalData) {
        await axios.post(`${this.apiUrl}/access/${id}`, additionalData ?? {});
    }

    async getBreed(id) {
        if(this.useCache) {
            const cache = await fetch(`/cache/RZ-${id}.json`);
            if(cache.ok) {
                return new Breed(await cache.json());
            }
        }
        
        const response = await axios.get(`${this.apiUrl}/breed/${id}`);
        return new Breed(response.data);
    }   
    async getBreedList() {
        if(this.useCache) {
            const cache = await fetch(`/cache/breeds.json`);
            if(cache.ok) {
                return (await cache.json()).map(x => new Breed(x));
            }
        }
        const response = await axios.get(`${this.apiUrl}/breed`);
        return response.data.map(breed => new Breed(breed));
    }
    async getEnciGroup(id) {
        if(this.useCache) {
            const cache = await fetch(`/cache/ENCI-${id}.json`);
            if(cache.ok) {
                const res = await cache.json();
                return new EnciGroup(res);
            }
        }
        const response = await axios.get(`${this.apiUrl}/enci-groups/${id}`);
        const enciGroup = new EnciGroup(response.data);
        enciGroup.breeds = await this.getEnciGroupBreeds(id);
        return new EnciGroup(response.data);
    }
    async getEnciGroupList() {
        if(this.useCache) {
            const cache = await fetch(`/cache/enci.json`);
            if(cache.ok) {
                return (await cache.json()).map(x => new EnciGroup(x));
            }
        }
        const response = await axios.get(`${this.apiUrl}/enci-groups`);
        return response.data.map(enciGroup => new EnciGroup(enciGroup));
    }
    
    async getMotivationalGroup(id) {
        if(this.useCache) {
            const cache = await fetch(`/cache/GM-${id}.json`);
            if(cache.ok) {
                const res = await cache.json();
                return new MotivationalGroup(res);
            }
        }
        const response = await axios.get(`${this.apiUrl}/motivation-group/${id}`);
        return new MotivationalGroup(response.data);
    }
    async getMotivationalGroupList() {
        if(this.useCache) {
            const cache = await fetch(`/cache/motivationGroups.json`);
            if(cache.ok) {
                return (await cache.json()).map(x => new MotivationalGroup(x));
            }
        }
        const response = await axios.get(`${this.apiUrl}/motivation-group`);
        return response.data.map(motivationalGroup => new MotivationalGroup(motivationalGroup));
    }
    
    async getEnciGroupBreeds(id) {
        const response = await axios.get(`${this.apiUrl}/breed?EnciGroupId=${id}`);
        return response.data.map(breed => new Breed(breed));
    }
    
    async login(username, password) {
        const response = await axios.post(`${this.apiUrl}/user/login`, { username, password });
        this.updateToken(response.data);
        return this.token;
    }

    logout() {
        this.updateToken(null);
        return null;
    }
    
    async validateToken() {
        if(!this.getToken()) {
            return null;
        }
        
        const response = await axios.post(`${this.apiUrl}/user/validate-token`, {}, {
            headers: {
                Authorization: `Bearer ${this.getToken()}`
            }
        });
        this.updateToken(response.data)
        return this.token;
    }
    
    async getAccesses(
        fromDate, 
        toDate, 
        filterByIp = null, 
        filterBySessionId = null, 
        loadElementViewed = false
    ) {
        let url = `${this.apiUrl}/access?fromDate=${fromDate.toISOString()}&toDate=${toDate.toISOString()}`;
        if(filterByIp) {
            url += `&ip=${filterByIp}`;
        }
        if(filterBySessionId) {
            url += `&sessionId=${filterBySessionId}`;
        }
        const response = await axios.get(url);
        let breeds = [];
        let motivationalClassifications = [];
        let enciGroups = [];
        
        if (loadElementViewed) {
            breeds = await this.getBreedList();
            motivationalClassifications = await this.getMotivationalGroupList();
            enciGroups = await this.getEnciGroupList();
        }
        
        
        return response.data?.map(x => new AccessLog(x, breeds, enciGroups, motivationalClassifications)) ?? [];
    }
    
    async getArticleCategories() { 
        if(this.useCache) {
            const cache = await fetch(`${this.baseUrl}/cache/articleCategories.json`);
            if(cache.ok) {
                return (await cache.json()).map(x => new ArticleCategory(x));
            }
        }
        const response = await axios.get(`${this.apiUrl}/article-category`);
        return response.data.map(x => new ArticleCategory(x));
    }
    
    async getArticleCategory(id) {
        if(this.useCache) {
            const cache = await fetch(`${this.baseUrl}/cache/AC-${id}.json`);
            if(cache.ok) {
                return new ArticleCategory(await cache.json());
            }
        }
        const response = await axios.get(`${this.apiUrl}/article-category/${id}`);
        return new ArticleCategory(response.data);
    }
    
    async getArticles(categoryId = null, loadCategory = false) {
        let url = `${this.apiUrl}/article`;
        let categories = null;
        
        if(categoryId) {
            url += `?categoryId=${categoryId}`;
        }
        
        if(loadCategory){
            if(categoryId) {
                categories = [await this.getArticleCategory(categoryId)];
            } else {
                categories = await this.getArticleCategories();
                console.log(categories);
            }
        }


        const response = await axios.get(url);        
        return response?.data?.map(x => new Article(x, categories)) ?? [];
    }
    
    async getArticle(id, loadCategory = false) {
        if(this.useCache) {
            const cache = await fetch(`${this.baseUrl}/cache/AR-${id}.json`);
            if(cache.ok) {
                return new Article(await cache.json());
            }
        }
        const response = await axios.get(`${this.apiUrl}/article/${id}`);
        let category = null;
        
        if(loadCategory) {
            category = await this.getArticleCategory(response.data.categoryId);
        }
        
        
        return new Article(response.data, [category]);
    }
    
    async getArticleSearchData(lang) {
        const cache = await fetch(`${this.baseUrl}/cache/articles.${lang.toLowerCase()}.json`);
        if(cache.ok) {
            return (await cache.json()).map(x => new ArticleCacheRow(x));
        }
    }
    
    async getCacheInfo() {
        const response = await axios.get(`${this.apiUrl}/admin/cache-info`);
        return response.data.map(x => new CacheFileGroupInfo(x));
    }
    
    async updateCache(cacheType) {
        if (!cacheType) {
            return false;
        }
        const response = await axios.post(`${this.apiUrl}/admin/update-cache/${cacheType}`);
        return response.data;        
    }
}

const apiService = new ApiService('https://cinopedia.cloud', true);
//const apiService = new ApiService('http://localhost:5210', true);
export default apiService;