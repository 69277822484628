import LocalString from "@/entities/LocalString";

export default class Motivation {
    constructor(data, index, total) {
        const steps = Math.round(total/3);
        const ranges = [
            {min: 0, max: steps},
            {min: steps, max: steps * 2},
            {min: steps * 2, max: 99999}
        ];
        
        this.id = data.id;
        this.level = index >= ranges[0].min && index < ranges[0].max 
            ? "high" 
            : index >= ranges[1].min && index < ranges[1].max 
                ? "medium" 
                : "low";
        this.externalId = data.externalId;
        this.name = new LocalString(data.name);
        this.description = new LocalString(data.description);
    }
}