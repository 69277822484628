<template>
  <v-toolbar class="menu-bar" >
    <img 
        src="../assets/logo-60x60.png" 
        alt="Cinopedia" 
        height="60" 
        width="60"
        @click="logoClick()"
        style="cursor:pointer;"
    >
    <v-spacer></v-spacer>
    <SearchAllAutocomplete
        ref="searchComponent"
        v-if="showSearch" 
        class="search" 
        variant="solo" 
        :label="getTranslation('search-your-dog')"
    />
    <v-menu transition="scale-transition">
      <template v-slot:activator="{ props }">
        <v-btn icon v-bind="props">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="navigateTo('/motivational-classifications')" class="list-item">
          <template v-slot:title>
            <img src="/img/light-bulb-icon.svg" width="15" :alt="getTranslation('motivational-groups')">
            {{getTranslation("motivational-groups")}}
          </template>
        </v-list-item>
        <v-list-item @click="navigateTo('/enci-groups')" class="list-item">
          <template v-slot:title>
            <img src="/img/dog-icon.svg" width="15" :alt="getTranslation('enci-groups')">
            {{getTranslation("enci-groups")}}
          </template>
        </v-list-item>
        <v-list-item @click="navigateTo('/breeds')" class="list-item">
          <template v-slot:title>
            <img src="/img/paw-print-icon.svg" width="15" :alt="getTranslation('breed-list')">
            {{getTranslation("breed-list")}}
          </template>
        </v-list-item>
        <v-list-item @click="navigateTo('/article-categories')" class="list-item">
          <template v-slot:title>
            <img src="/img/newsstand.svg" width="15" :alt="getTranslation('articles')">
            {{getTranslation("articles")}}
          </template>
        </v-list-item>
        <v-list-item @click="navigateTo('/clicker')" class="list-item">
          <template v-slot:title>
            <img src="/img/clicker.svg" width="15" :alt="getTranslation('articles')">
            {{getTranslation("clicker")}}
          </template>
        </v-list-item>
        <v-list-item @click="toggleTheme()" class="list-item">
          <template v-slot:title>
            <img src="/img/theme.svg" width="15" :alt="getTranslation('articles')">
            {{isDarkTheme ? getTranslation("light-theme") : getTranslation("dark-theme")}}
          </template>
        </v-list-item>
        <v-list-item @click="showCookieBanner()" class="list-item">
          <template v-slot:title>
            <img src="/img/cookie.svg" width="15" :alt="getTranslation('articles')">
            {{getTranslation('change-cookie-settings')}}
          </template>
        </v-list-item>
        <v-list-item @click="navigateTo('/accesses')" class="list-item" v-if="isLogged">
          <template v-slot:title>
            <v-icon>mdi-skull-scan</v-icon>
            {{getTranslation("accesses")}}
          </template>
        </v-list-item>
        <v-list-item @click="navigateTo('/admin-cache')" class="list-item" v-if="isLogged">
          <template v-slot:title>
            <v-icon>mdi-cached</v-icon>
            {{getTranslation("cache")}}
          </template>
        </v-list-item>
        <v-list-item @click="logout()" class="list-item" v-if="isLogged">
          <template v-slot:title>
            <v-icon>mdi-logout</v-icon>
            {{getTranslation("logout")}}
          </template>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-toolbar>
</template>

<script>
import {getTranslation} from "@/services/stringTableService";
import SearchAllAutocomplete from "@/components/SearchAllAutocomplete.vue";
import apiService from "@/services/ApiService";
import sidService from "@/services/sidService";

export default {
  name: 'MenuBar',
  components: {SearchAllAutocomplete},
  data() {
    return {
      showSearch: true
    }
  },
  methods: {
    showCookieBanner() {
      this.$store.dispatch('setShowCookieBanner', true);
    },
    logoClick() {
      if (this.$route.path === '/') {
        const sessionId = sidService.getSid();
        navigator.clipboard.writeText(sessionId).then(() => {
          console.log('Session ID copied to clipboard');
        }).catch(err => {
          console.error('Failed to copy session ID: ', err);
        });
      } else {
        this.navigateTo('/');
      }
    },
    navigateTo(route) {
      this.$router.push(route);
    },
    getTranslation(key) {
      return getTranslation("it", key)
    },
    logout() {
      this.$store.dispatch('setAuthToken', null);
      apiService.logout();
      this.$router.push({ name: 'Home' });
    },
    toggleTheme() {
      this.$store.dispatch('setTheme', !this.isDarkTheme);
    },
  },
  computed: {
    isLogged() {
      return !!this.$store.state.authToken;
    },
    isDarkTheme(){
      return this.$store.state.isDarkTheme
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../style/_variables.scss';
.menu-bar {
  background: $primary-gradient;
  color: $primary-contrast-color;
  .search {
    width: 100%;
    min-width: 180px;
    max-width: 300px;
  }
}
.list-item {
  font-family: $font-base;
  padding: 0;
  .v-list-item__title {
    font-size: 12px;
    font-weight: 300;
  }
  .v-list-item__subtitle {
    font-size: 12px;
  }

  &:nth-child(odd) {
    background-color: rgba($primary-color, 0.2);
  }
}
</style>