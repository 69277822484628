<template>
  <div class="article-container" itemscope itemtype="https://schema.org/NewsArticle">
    <div class="title-box primary-box">
      <h3 class="article-category">
        <router-link class="category-link" :to="articleCategoryLink" itemprop="url">
          {{ category.title?.get(language) ?? "" }}
        </router-link>
      </h3>
      <h2 itemprop="headline">{{ article?.title?.get(language) }}</h2>
      <h3>{{ article?.subtitle?.get(language) }}</h3>
      <p style="display: none" itemprop="datePublished" :content="article?.onlineFrom?.toISOString()">
        {{article.onlineFrom}}
      </p>
      <p style="display: none" itemprop="author" itemtype="https://schema.org/Person">
        <span itemprop="name">Cinopedia.cloud</span>
      </p>
    </div>
<!--    <img :src="article.imageUrl" alt="Article image" class="article-image" itemprop="image" v-if="article.imgUrl">-->
<!--    <img :src="isDarkTheme ? '/img/newspaper-light.svg' : '/img/newspaper.svg'" alt="Article image" class="article-image" itemprop="image" v-else>-->
    <div class="article-content-container">
      <p v-html="renderedMarkdown"></p>
    </div>
    <div class="button-container">
      <v-btn-group density="compact" rounded>
        <v-btn :disabled="!previousArticleLink" :to="previousArticleLink"><v-icon>mdi-chevron-left</v-icon></v-btn>
        <v-btn :disabled="!nextArticleLink" :to="nextArticleLink"><v-icon>mdi-chevron-right</v-icon></v-btn>
      </v-btn-group>
    </div>
  </div>
</template>

<script>
import apiService from "@/services/ApiService";
import { getTranslation } from "@/services/stringTableService";
import { updateMetaInfo } from "@/middleware/metaUpdater";
import MarkdownIt from "markdown-it";

export default {
  data() {
    return {
      category: {},
      article: {},
      previousArticleLink: null,
      nextArticleLink: null,
      language: "IT",
      articleCategoryLink: ""
    };
  },
  methods: {
    getTranslation,
    getArticleCategoryLink(category) {
      const {id, slug} = category;
      return `/article-category/${id}/${slug?.get(this.language)}`;
    },
    getArticleLink(article) {
      if(!article) {
        return null;
      }
      return `/article/${article.id}/${article.slug?.get(this.language)}`;
    },
    getNextArticle(articles, currArticleId) {
      const currentIndex = articles.findIndex(article => article.id === currArticleId);
      if (currentIndex === -1) {
        return;
      }
      if (currentIndex < articles.length - 1) {
        return this.getArticleLink(articles[currentIndex + 1]);
      }

      return null;
    },
    getPreviousArticle(articles, currArticleId) {
      const currentIndex = articles.findIndex(article => article.id === currArticleId);
      if (currentIndex === -1) {
        return;
      }
      if (currentIndex > 0) {
        return this.getArticleLink(articles[currentIndex - 1]);
      }
      return null;
    },
    async fetchArticle() {
      try {
        this.$store.dispatch('setLoading', true);
        const articleId = this.$route.params.id;
        const article = await apiService.getArticle(articleId, true);
        this.category = article.category;
        this.article = article;
        this.articleCategoryLink = this.getArticleCategoryLink(article.category);
        
        this.nextArticleLink = article.nextArticleLink.get(this.language);
        this.previousArticleLink = article.prevArticleLink.get(this.language);
        
        this.updateUrlWithSlug(article);
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch('setLoading', false);
      }
    },
    updateUrlWithSlug(data) {
      this.$router.replace({
        name: 'ArticleDetail',
        params: {id: data.id, slug: data.slug.get(this.language)},
      });
    },
  },
  computed: {
    renderedMarkdown() {
      const articleContent = this.article.content?.get(this.language) || "";
      
      if(!articleContent) {
        return "";
      }

      const markdown = new MarkdownIt({
        html: true
      });
      
      return markdown.render(articleContent);
    },
    metaInfo() {
      return {
        title: `${this.article?.title?.get(this.language)} - Cinopedia.cloud`,
        description: `Articolo: ${this.article?.title?.get(this.language)}`,
        keywords: `razza,razze,fci,enci,cani,cinofilia,articoli,${this.category?.title?.get(this.language)}`,
      };
    },
    isDarkTheme() {
      return this.$store.state.isDarkTheme;
    },
  },
  watch: {
    $route(to, from) {
      if (to.params.id !== from.params.id) {
        this.fetchArticle();
      }
    },
    article() {
      updateMetaInfo(this.metaInfo);
    },
  },
  async created() {
    await this.fetchArticle();
  },
};
</script>

<style scoped lang="scss">
@import "../style/_variables.scss";

.article-container {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: $secondary-background-color;
  h2 {
    font-size: 1.8rem;
  }
  .article-category {
    .category-link {
      text-decoration: underline;
    }
  }
  .article-image {
    width: 200px;
    margin: 20px 0;
  }
  
  
  a {
    color: $primary-contrast-color;
  }
  
  .article-content-container {
    text-align: left;
    padding: 20px;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    flex-flow: column;
    flex-wrap: wrap;
    gap: 5px;
    .v-btn {
      color: $primary-contrast-color;
      background: $primary-gradient;
      &:nth-child(odd) {
        background: $secondary-gradient;
      }
    }
  }
}

h2, h3, h4, p {
  font-family: $header-font;
}
</style>