import LocalString from "@/entities/LocalString";
import MotivationalGroup from "@/entities/MotivationalGroup";

export default class Breed {
    constructor(data) {
        this.externalId = data.externalId;
        this.fciCode = data.fciCode;
        this.enciGroupId = data.enciGroupId;
        this.enciSection = new LocalString(data.enciSection);
        this.name = new LocalString(data.name);
        this.history = new LocalString(data.history);
        this.description = new LocalString(data.description);
        this.standardPdfLink = new LocalString(data.standardPdfLink);
        this.enciLink = data.enciLink;
        this.fciLink = data.fciLink;
        this.imageUrl = data.imageUrl;
        this.motivationalGroupId = data.motivationGroupId;
        this.motivationalGroup = data.motivationGroup ? new MotivationalGroup(data.motivationGroup) : null;
        this.id = data.id;
    }
}