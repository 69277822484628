<template>
  <div class="enci-group-detail-container">
    <div class="title-box primary-box">
      <h1>{{ getTranslation(language, "breed-list")}} FCI/ENCI</h1>
    </div>
<!--    <v-card class="list-card" density="comfortable" v-if="!$vuetify.display.mdAndUp">-->
<!--      <v-list>-->
<!--        <v-list-item-->
<!--            v-for="breed in breeds"-->
<!--            :key="breed.id"-->
<!--            class="list-item"-->
<!--            @click="navigateToBreedDetail(breed.id)"-->
<!--        >-->
<!--          <template v-slot:append>-->
<!--              <img :src="breed.imageUrl" :alt="breed.name" width="50">-->
<!--          </template>-->
<!--          <v-list-item-title>{{ breed.name?.get(language)}}</v-list-item-title>-->
<!--          <v-list-item-subtitle>{{ breed.enciSection?.get(language)}}</v-list-item-subtitle>-->
<!--        </v-list-item>-->
<!--      </v-list>-->
<!--    </v-card>-->
    <div class="card-deck">
      <v-card 
          class="card" 
          v-for="breed in breeds" :key="breed.id"
          itemscope itemtype="https://schema.org/NewsArticle"
      >
        <router-link :to="`/breed/${breed.id}`" itemprop="url">
          <v-img :src="breed.imageUrl" :alt="breed.name" height="200px"></v-img>

          <p style="display: none" itemprop="image">{{breed.imageUrl}}</p>
          <div class="card-description">
            <v-card-title itemprop="headline">{{ breed.name?.get(language) }}</v-card-title>
            <v-card-subtitle>{{ breed.enciSection?.get(language) }}</v-card-subtitle>
          </div>

          <p style="display: none" itemprop="author" itemtype="https://schema.org/Person">
            <span itemprop="name">Cinopedia.cloud</span>
          </p>
        </router-link>
      </v-card>
    </div>
  </div>
</template>

<script>
import apiService from "@/services/ApiService";
import {getTranslation} from "@/services/stringTableService";
import {updateMetaInfo} from "@/middleware/metaUpdater";

export default {
  methods: {
    getTranslation,
    async fetchBreeds() {
      try {
        this.$store.dispatch('setLoading', true);
        const breeds = await apiService.getBreedList();
        this.breeds = breeds.sort((a, b) => {
          const nameA = a.name?.get(this.language).toUpperCase();
          const nameB = b.name?.get(this.language).toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch('setLoading', false);
      }
    }
  },
  data() {
    return {
      breeds: [],
      language: "IT"
    };
  },
  async created() {
    await this.fetchBreeds();
    
    updateMetaInfo({
      title: "Razze - Cinopedia.cloud",
      description: "Lista delle razze FCI/ENCI - Cinopedia.cloud",
    })

    window.scrollTo(0, 0)
  }
};
</script>

<style scoped lang="scss">
@import "../style/_variables.scss";

.enci-group-detail-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  h1, h2 {
    font-family: $header-font;
    font-weight: bolder;
  }
  .section-select {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 350px;
  }
  .list-card {
    margin-top: 10px;
    overflow-y: auto;
    max-height: 70vh;
  }
  .list-item {
    text-align: left;
    max-width: 90vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    cursor: pointer;
    :hover {
      color: $primary-color;
    }
    .v-list-item-title, .v-list-item-subtitle {
      text-overflow: unset !important;
      white-space: normal !important;
    }
  }
}
</style>