<template>
  <v-autocomplete
      ref="inputField"
      class="search-all-autocomplete"
      v-model="search"
      :items="items"
      hide-details
      variant="solo"
      :label="label"
      clearable
      @update:modelValue="handleSelect"
      :menu-props="{maxWidth: '300px'}"
      flat
      density="compact"
      rounded
  >
    <template v-slot:item="{ props, item }">
      <v-list-item
          class="list-item"
          v-bind="props"
      >
        <template v-slot:subtitle>
          <img src="/img/newsstand.svg" width="15" alt="news category">
          {{ getItem(item.value)?.categoryTitle }}
        </template>
        <template v-slot:title>
          {{ getItem(item.value)?.title }}
        </template>
        
      </v-list-item>
    </template>
  </v-autocomplete>
</template>
<script>
import {getTranslation} from "@/services/stringTableService";
import apiService from "@/services/ApiService";

export default {
  name: 'SearchArticleAutocomplete',
  props: ['label', 'variant'],
  data() {
    return {
      search: null,
      items: [],
      language: "IT",
      articles: []
    }
  },
  created() {
    this.fetchItems();
  },
  watch: {
    $route() {
      this.search = null;
    }
  },
  methods: {
    getTranslation,
    async fetchItems() {
      const articles = await apiService.getArticleSearchData(this.language);
      this.items = articles?.map(a => {
        return {
          title: a.title,
          value: a.id
        }
      })
      this.articles = articles;
    },
    getIconName() {
      return '/img/newspaper.svg';
    },
    getItem(id) {
      return this.articles?.find(a => a.id === id);
    },
    handleSelect(item) {
      if (!item) return;
      const { id, slug } = this.getItem(item);
      this.$router.push({ name: 'ArticleDetail', params: { id, slug }});
    }
  }
}
</script>
<style scoped lang="scss">
@import '../style/_variables.scss';
.list-item {
  font-family: $font-base;
  padding: 0;
  .v-list-item__title {
    font-size: 12px;
    font-weight: 300;
  }
  .v-list-item__subtitle {
    font-size: 12px;
  }

  &:nth-child(odd) {
    background-color: rgba($primary-color, 0.2);
  }
}
.v-overlay-container {
  width: 250px !important;
}
</style>
