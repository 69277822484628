import CacheFileInfo, {CacheType, formatSize} from "@/entities/CacheFileInfo";

export default class CacheFileGroupInfo {
    constructor(data) {
        this.typeId = data.type
        this.type = CacheType[data.type.toString()];
        this.filesInfo = data.filesInfo
            .map(fileInfo => new CacheFileInfo(fileInfo));
        this.filesCount = data.filesCount;
        this.totalSize = data.totalSize;
        this.formattedTotalSize = formatSize(data.totalSize);
        this.lastUpdate = new Date(data.lastUpdate);
    }
}