<template>
  <div class="accesses-container">
    <div class="filter-form-container">
      <form class="primary-box" @submit.prevent="fetchAccesses">
        <v-date-input 
            v-model="fromDate" 
            label="From" 
            required
            class="form-input"
            variant="solo"
        >          
        </v-date-input>
        <v-date-input 
            v-model="toDate" 
            label="To" 
            required
            class="form-input"
            variant="solo"
            
        >          
        </v-date-input>
        <v-text-field 
          v-model="filterByIp"
          label="Filter by IP"
          class="form-input"
          clearable
          variant="solo"
        />
        <v-text-field 
          v-model="filterBySessionId"
          label="Filter by Session"
          class="form-input"
          clearable
          variant="solo"
        />
        <div class="form-button-container">
          <v-btn type="submit" class="tertiary-bg-color">Fetch</v-btn>
        </div>
      </form>
    </div>
    <div class="button-container" v-if="accesses.length > 0">
      <v-btn-group density="compact" rounded>
        <v-btn @click="processDataForLocationTable">Location</v-btn>
        <v-btn @click="processDataForElementViewedTable">Element Viewed</v-btn>
      </v-btn-group>
      <v-btn-group density="compact" rounded>
        <v-btn @click="processDataForChartsByHours">Access Charts</v-btn>
        <v-btn @click="() => {clearData(); showCustomGroupBy = !showCustomGroupBy}">Custom Group By</v-btn>
      </v-btn-group>
    </div>
    <div class="select-container" v-if="allAccessesChartData && allAccessesChartData.labels">
      <v-select
          v-model="hoursStep"
          :items="hoursSteps"
          label="Select Hours Step"
          style="min-width: 250px;"
          clearable
          variant="solo"
      ></v-select>
    </div>
    <div class="chart-box"
         v-if="allAccessesChartData && allAccessesChartData.labels">
      <accesses-chart
          :chart-data="allAccessesChartData"
          :chart-options="chartOptions"
      >
      </accesses-chart>
    </div>
    <div class="chart-box" v-if="groupedAccessesChartData && groupedAccessesChartData.labels">
      <accesses-chart
          :chart-data="groupedAccessesChartData"
          :chart-options="chartOptions"
      >
      </accesses-chart>
    </div>
    <div class="data-table-container" v-if="locationData">
      <v-data-table
          v-if="locationData.length"
          :headers="locationHeaders"
          :items="combinedLocationData"
          :items-per-page="10"
          density="compact"
          class="elevation-1"
      >
      </v-data-table>
    </div>
    <div class="data-table-container" v-if="elementViewedData">
      <v-data-table
          v-if="elementViewedData.length"
          :headers="elementViewedHeaders"
          :items="elementViewedData"
          :items-per-page="10"
          density="compact"
          class="elevation-1"
      >
      </v-data-table>
    </div>
    <div class="custom-group-by-container" v-if="showCustomGroupBy">
      <div class="custom-group-by-form primary-box">
        <v-select
            v-model="selectedKeys"
            :items="groupKeyList"
            label="Select Group By"
            multiple
            style="min-width: 350px;"
            clearable
            variant="solo"
        ></v-select>
        <div class="select-container" v-if="selectedKeys.includes('date')">
          <v-select
              v-model="hoursStep"
              :items="hoursSteps"
              label="Select Hours Step"
              style="min-width: 250px;"
              variant="solo"
          ></v-select>
        </div>
        <div style="display: flex; justify-content: center">
          <v-btn class="tertiary-bg-color" @click="processBySelectedKeys">Update</v-btn>
        </div>
      </div>
      <div class="custom-group-by-chart">
        <accesses-chart
            v-if="chartData && chartData.labels"
            :chart-data="chartData"
            :chart-options="chartOptions"
        >
        </accesses-chart>
      </div>
      <div  class="custom-group-by-table"> 
        <v-data-table
          v-if="tableTotals && tableTotals.length"
          :headers="tableTotalsHeaders"
          :items="tableTotals"
          :items-per-page="10"
          :hide-default-footer="tableTotals.length < 10"
          density="compact"
          class="elevation-1"
          v-model:expanded="expanded"
          item-value="key"
          :show-expand="tableTotals?.reduce((acc, curr) => acc + (curr?.rows?.length ?? 0), 0) > 0"
      >
            <template v-slot:expanded-row="{item, columns}">
              <tr>
                <td :colspan="columns.length">
                  <v-data-table
                      :headers="item?.rows?.reduce((acc, curr) => acc + curr.rows.length, 0) > 0 ? tableTotalsHeaders : tableTotalsHeadersLast"
                      :items="item.rows"
                      :items-per-page="99999"
                      hide-default-footer
                      density="compact"
                      class="elevation-1"
                      v-model:expanded="expanded_1"
                      item-value="key"
                      :show-expand="item?.rows?.reduce((acc, curr) => acc + curr.rows.length, 0) > 0"
                  >
                    <template v-slot:expanded-row="{item, columns}">
                      <tr>
                        <td :colspan="columns.length">
                          <v-data-table
                              :headers="item?.rows?.reduce((acc, curr) => acc + curr.rows.length, 0) > 0 ? tableTotalsHeaders : tableTotalsHeadersLast"
                              :items="item.rows"
                              :items-per-page="99999"
                              hide-default-footer
                              density="compact"
                              class="elevation-1"
                              v-model:expanded="expanded_2"
                              item-value="key"
                              :show-expand="item?.rows?.reduce((acc, curr) => acc + curr.rows.length, 0) > 0"
                          >
                            <template v-slot:expanded-row="{item, columns}">
                              <tr>
                                <td :colspan="columns.length">
                                  <v-data-table
                                      :headers="item?.rows?.reduce((acc, curr) => acc + curr.rows.length, 0) > 0 ? tableTotalsHeaders : tableTotalsHeadersLast"
                                      :items="item.rows"
                                      :items-per-page="99999"
                                      hide-default-footer
                                      density="compact"
                                      class="elevation-1"
                                      v-model:expanded="expanded_3"
                                      item-value="key"
                                      :show-expand="item?.rows?.reduce((acc, curr) => acc + curr.rows.length, 0) > 0"
                                  >
                                    <template v-slot:expanded-row="{item, columns}">
                                      <tr>
                                        <td :colspan="columns.length">
                                          <v-data-table
                                              :headers="item?.rows?.reduce((acc, curr) => acc + curr.rows.length, 0) > 0 ? tableTotalsHeaders : tableTotalsHeadersLast"
                                              :items="item.rows"
                                              :items-per-page="99999"
                                              hide-default-footer
                                              density="compact"
                                              class="elevation-1"
                                              v-model:expanded="expanded_4"
                                              item-value="key"
                                              :show-expand="item?.rows?.reduce((acc, curr) => acc + curr.rows.length, 0) > 0"
                                          >
                                            <template v-slot:expanded-row="{item, columns}">
                                              <tr>
                                                <td :colspan="columns.length">
                                                  <v-data-table
                                                      :headers="item?.rows?.reduce((acc, curr) => acc + curr.rows.length, 0) > 0 ? tableTotalsHeaders : tableTotalsHeadersLast"
                                                      :items="item.rows"
                                                      :items-per-page="99999"
                                                      hide-default-footer
                                                      density="compact"
                                                      class="elevation-1"
                                                      v-model:expanded="expanded_5"
                                                      item-value="key"
                                                      :show-expand="item?.rows?.reduce((acc, curr) => acc + curr.rows.length, 0) > 0"
                                                  >
                                                    <template v-slot:expanded-row="{item, columns}">
                                                      <tr>
                                                        <td :colspan="columns.length">
                                                          <v-data-table
                                                              :headers="item?.rows?.reduce((acc, curr) => acc + curr.rows.length, 0) > 0 ? tableTotalsHeaders : tableTotalsHeadersLast"
                                                              :items="item.rows"
                                                              :items-per-page="99999"
                                                              hide-default-footer
                                                              density="compact"
                                                              class="elevation-1"
                                                              v-model:expanded="expanded_6"
                                                              item-value="key"
                                                              :show-expand="item?.rows?.reduce((acc, curr) => acc + curr.rows.length, 0) > 0"
                                                          >
                                                            <template v-slot:expanded-row="{item, columns}">
                                                              <tr>
                                                                <td :colspan="columns.length">
                                                                  <v-data-table
                                                                      :headers="item?.rows?.reduce((acc, curr) => acc + curr.rows.length, 0) > 0 ? tableTotalsHeaders : tableTotalsHeadersLast"
                                                                      :items="item.rows"
                                                                      :items-per-page="99999"
                                                                      hide-default-footer
                                                                      density="compact"
                                                                      class="elevation-1"
                                                                      v-model:expanded="expanded_7"
                                                                      item-value="key"
                                                                      :show-expand="item?.rows?.reduce((acc, curr) => acc + curr.rows.length, 0) > 0"
                                                                  >
                                                                    <template v-slot:expanded-row="{item, columns}">
                                                                      <tr>
                                                                        <td :colspan="columns.length">
                                                                          <v-data-table
                                                                              :headers="item?.rows?.reduce((acc, curr) => acc + curr.rows.length, 0) > 0 ? tableTotalsHeaders : tableTotalsHeadersLast"
                                                                              :items="item.rows"
                                                                              :items-per-page="99999"
                                                                              hide-default-footer
                                                                              density="compact"
                                                                              class="elevation-1"
                                                                              v-model:expanded="expanded_8"
                                                                              item-value="key"
                                                                              :show-expand="item?.rows?.reduce((acc, curr) => acc + curr.rows.length, 0) > 0"
                                                                          >
                                                                            <template v-slot:expanded-row="{item, columns}">
                                                                              <tr>
                                                                                <td :colspan="columns.length">
                                                                                  <v-data-table
                                                                                      :headers="item?.rows?.reduce((acc, curr) => acc + curr.rows.length, 0) > 0 ? tableTotalsHeaders : tableTotalsHeadersLast"
                                                                                      :items="item.rows"
                                                                                      :items-per-page="99999"
                                                                                      hide-default-footer
                                                                                      density="compact"
                                                                                      class="elevation-1"
                                                                                      v-model:expanded="expanded_9"
                                                                                      item-value="key"
                                                                                  >
                                                                                  </v-data-table>
                                                                                </td>
                                                                              </tr>
                                                                            </template>
                                                                          </v-data-table>
                                                                        </td>
                                                                      </tr>
                                                                    </template>
                                                                  </v-data-table>
                                                                </td>
                                                              </tr>
                                                            </template>
                                                          </v-data-table>
                                                        </td>
                                                      </tr>
                                                    </template>
                                                  </v-data-table>
                                                </td>
                                              </tr>
                                            </template>
                                          </v-data-table>
                                        </td>
                                      </tr>
                                    </template>
                                  </v-data-table>
                                </td>
                              </tr>
                            </template>
                          </v-data-table>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </td>
              </tr>
            </template>
      </v-data-table>
      </div>
      <div  class="custom-group-by-table">
        <v-data-table
            v-if="tableData.length"
            :headers="tableHeaders"
            :items="tableData"
            :items-per-page="10"
            density="compact"
            class="elevation-1"
        >
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import { getTranslation } from "@/services/stringTableService";
import apiService from "@/services/ApiService";
import AccessesChart from "@/components/AccessesChart";
import {groupBy} from "@/utils/aggregation";
import {weekDays} from "@/entities/AccessLog";

export default {
  components: {
    AccessesChart
  },
  methods: {
    getTranslation,
    async fetchAccesses() {
      this.$store.dispatch('setLoading', true);
      try {
        this.clearData()
        const fromDate = new Date(this.fromDate);
        fromDate.setHours(0, 0, 0, 0);

        const toDate = new Date(this.toDate);
        toDate.setHours(23, 59, 59, 999);

        const accesses = await apiService.getAccesses(
            fromDate, 
            toDate, 
            this.filterByIp,
            this.filterBySessionId,
            true
        );
        this.accesses = accesses;
        return accesses;
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch('setLoading', false);
      }
    },
    clearData() {
      this.allAccessesChartData = {};
      this.groupedAccessesChartData = {};
      this.locationData = [];
      this.elementViewedData = [];
      
      this.chartData = {};
      this.tableData = [];
      this.tableHeaders = [];
      this.tableTotals = [];
      
      this.selectedKeys = [];
      
      this.showCustomGroupBy = false;
    },
    
    async processDataForLocationTable() {
      this.clearData();
      this.locationData = this.groupByLocation(this.accesses);
    },
    async processDataForElementViewedTable() {
      this.clearData();      
      this.elementViewedData = this.groupByElementViewed(this.accesses);
    },
    async processDataForChartsByHours() { 
      this.clearData();
      const allAccessesData = this.groupByDate(this.accesses);
      const groupedAccessesData = this.groupBySessionId(this.accesses);
      
      const allAccessesChartData = {
        labels: allAccessesData.labels,
        datasets: [
          {
            label: 'All interactions',
            data: allAccessesData.data,
            borderColor: 'rgba(185, 16, 72, 1)',
            backgroundColor: 'rgba(185, 16, 72, 0.2)',
            color: 'rgba(255, 255, 255, 1)',
            fill: true
          }
        ]
      };
      const groupedAccessesChartData = {
        labels: groupedAccessesData.labels,
        datasets: [
          {
            label: 'New Sessions',
            data: groupedAccessesData.data,
            borderColor: 'rgba(185, 16, 72, 1)',
            backgroundColor: 'rgba(185, 16, 72, 0.2)',
            color: 'rgba(255, 255, 255, 1)',
            fill: true
          }
        ]
      };      
      
      this.allAccessesChartData = allAccessesChartData

      this.groupedAccessesChartData = groupedAccessesChartData
    },
    
    groupDataByHour(dataset) {
      const grouped = {};
      dataset.forEach(access => {
        const date = new Date(access.date);
        const groupHour = (Math.floor(date.getHours() / this.hoursStep) * this.hoursStep)
            .toString()
            .padStart(2, '0');
        const key = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${groupHour}:00`;
        if (!grouped[key]) {
          grouped[key] = 0;
        }
        grouped[key]++;
      });
      
      const labels = Object.keys(grouped).sort();
      const data = labels.map(label => grouped[label]);
      
      return { labels, data };
    },
    groupByDate(accesses) {
      return this.groupDataByHour(accesses);
    },
    groupBySessionId(accesses) {
      let sessionCount = accesses.reduce((acc, access) => {
        if (!acc[access.sessionId]) {
          acc[access.sessionId] = { count: 0, date: new Date(access.date) };
        }
        acc[access.sessionId] = { 
          count: acc[access.sessionId].count + 1, 
          date: new Date(access.date), 
          sessionId: access.sessionId 
        };
        return acc;
      }, {});
      
      sessionCount = Object.values(sessionCount);
      
      return this.groupDataByHour(sessionCount);
    },
    groupByLocation(accesses) {
      const grouped = {};
      accesses.forEach(access => {
        const key = `${access.country}-${access.regionName}-${access.city}-${access.isp}-${access.ip}`;
        if (!grouped[key]) {
          grouped[key] = {
            country: access.country,
            regionName: access.regionName,
            city: access.city,
            isp: access.isp,
            ip: access.ip,
            count: 0
          };
        }
        grouped[key].count++;
      });
      
      const result = Object.values(grouped);
      
      return result;
    },
    groupByElementViewed(accesses) {
      const grouped = {};
      accesses.forEach(access => {
        const key = access.elementViewed;
        if (!grouped[key]) {
          grouped[key] = {
            elementViewed: access.elementViewed,
            count: 0
          };
        }
        grouped[key] = { 
          count: grouped[key].count + 1, 
          elementViewed: access.elementViewed 
        };
      });
      return Object.values(grouped);
    },
    
    baseGetKey(keyList, timeStep = null) {
      return data => {
        let result = ''
        for(let key of keyList){
          if(key === 'date'){
            const date = new Date(data[key]);
            const groupHour = (Math.floor(date.getHours() / timeStep) * timeStep)
                .toString()
                .padStart(2, '0');            
            
            result += result ? '-' : '';
            result += `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${groupHour}:00`;
          } else {
            result += result ? '-' : '';
            result += data[key];
          }
        }
        return result;
      }
    },
    baseGroupTransform(keyList){
      return (group, key, data) => {
        if(!group) {
          group = {};
          for (let propKey of keyList) {
            if(propKey === 'date'){
              const date = new Date(data[propKey]);
              const groupHour = (Math.floor(date.getHours() / this.hoursStep) * this.hoursStep)
                  .toString()
                  .padStart(2, '0');
              group[propKey] = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${groupHour}:00`;
            } else if (propKey === 'weekDay') {
              group[propKey] = weekDays[data[propKey]]
            } else {
              group[propKey] = data[propKey];
            }
          }
          group.key = key;
          group.count = 0;
          group.rows = [data];
        } else {
          group.count++;
          group.rows.push(data);
        }
        
        return group;
      }
    },
    processLevel(data, keys, level = 1){
      const currentLevel = {};
      const key = keys[0];
      for (let row of data){
        if(!currentLevel[row[key]]){
          currentLevel[row[key]] = {
            count: 0,
            key: `${row[key]}`,
            rows: [],
            total: 0
          };
        }
        currentLevel[row[key]].count += 1;
        currentLevel[row[key]].total += row.count;
        currentLevel[row[key]].avg = currentLevel[row[key]].total / currentLevel[row[key]].count;
        currentLevel[row[key]].min = Math.min(currentLevel[row[key]].min ?? Infinity, row.count);
        currentLevel[row[key]].max = Math.max(currentLevel[row[key]].max ?? -Infinity, row.count);
      }
      if(keys.length > 1){
        for (let objKey of Object.keys(currentLevel)){
          currentLevel[objKey].rows = this.processLevel(
              data.filter(r => r[key] === currentLevel[objKey].key),
              keys.slice(1),
              level + 1
          );
          if(level === 1)
            console.log(key + ' - ' + currentLevel[objKey])

          currentLevel[objKey].count = currentLevel[objKey].rows.length;
        }
      }
      
      
      return Object.values(currentLevel);
    },
    // flatTotals(rows){
    //   let result = [];
    //   for(let row of rows) {
    //     result.push(row);
    //    
    //     if(row.rows.length > 0) {
    //       result = [...result, ...this.flatTotals(row.rows, result)];
    //     }     
    //   }  
    //   return result
    // },
    getTotals(data, groupKeys){
      if(groupKeys.length > 1){
        const result = this.processLevel(data, groupKeys);
        console.log(result); 
        return result;
        // const flatResult = this.flatTotals(result);
        // console.log(flatResult);
        // return flatResult;
      }
      
      const totals = {};
      for(let key of groupKeys){

        const processedKeys = {};
        const count = data.reduce((acc, row) => {
          if(processedKeys[row[key]]) return acc;
          processedKeys[row[key]] = true;
          return acc + (row[key] ? 1 : 0);
        }, 0);

        totals[key] = {
          count: count,
          avg: data.reduce((acc, row) => {
            return acc + row.count;
          }, 0) / data.length,
          min: data.reduce((acc, row) => {
            return Math.min(acc, row.count);
          }, Infinity),
          max: data.reduce((acc, row) => {
            return Math.max(acc, row.count);
          }, -Infinity),
          total: data.reduce((acc, row) => {
            console.log(row[key]);
            return acc + (row.count ?? 0);
          }, 0),
          key
        };
      }
      return Object.values(totals);
    },
    processBySelectedKeys(){
      const getKey = this.baseGetKey(this.selectedKeys, this.hoursStep);
      const transform = this.baseGroupTransform(this.selectedKeys);
      const grouped = groupBy(this.accesses, getKey, transform);
      
      const forTable = Object.values(grouped);
      console.log(forTable);
      const labels = Object.keys(grouped).sort();
      const data = [
        {
          label: this.selectedKeys.join('-'),
          data: labels.map(label => grouped[label].count),
          borderColor: 'rgba(185, 16, 72, 1)',
          backgroundColor: 'rgba(185, 16, 72, 0.2)',
          color: 'rgba(255, 255, 255, 1)',
          fill: true
        }
      ];
      
      this.tableData = forTable;
      
      this.tableTotals = this.getTotals(forTable, this.selectedKeys);
      
      
      this.chartData = {
        labels,
        datasets: data
      };
      const tableHeaders = this.selectedKeys.map(key => ({ title: key, value: key, sortable: true }));
      tableHeaders.push({ title: 'Count', value: 'count', sortable: true });
      this.tableHeaders = tableHeaders;
      
    }
  },
  data() {
    const today = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(today.getDate() - 7);
    return {
      accesses: [],
      filterByIp: null,
      filterBySessionId: null,
      fromDate: sevenDaysAgo,
      toDate: today,
      language: "IT",
      hoursStep: 6,
      hoursSteps: [1, 2, 6, 12, 24],
      locationData: [],
      elementViewedData: [],
      motivationalClassifications: [],
      enciGroups: [],
      breeds: [],
      
      expanded: [],
      expanded_1: [],
      expanded_2: [],
      expanded_3: [],
      expanded_4: [],
      expanded_5: [],
      expanded_6: [],
      expanded_7: [],
      expanded_8: [],
      expanded_9: [],
      
      showCustomGroupBy: false,
      groupKeyList: [
         'date', 'hour', 'day', "month", "year", "weekDay",
         'country', 'regionName', 'city', "location", 'isp', 'ip', "sessionId",
         "userAgent", "deviceType", "device", "browser", "os", "cpu",
         "elementViewed", "path"
      ],
      selectedKeys: [],      
      chartData: {},
      tableData: [],
      tableTotals: [],
      tableTotalsHeaders: [
        { title: 'Key', value: 'key', sortable: true },
        { title: 'Cnt', value: 'count', sortable: true },
        { title: 'Avg', value: 'avg', sortable: true },
        { title: 'Min', value: 'min', sortable: true },
        { title: 'Max', value: 'max', sortable: true },
        { title: 'Tot', value: 'total', sortable: true }
      ],
      tableTotalsHeadersLast: [
        { title: 'Key', value: 'key', sortable: true },
        { title: 'Tot', value: 'total', sortable: true }
      ],
      tableHeaders: [],
      
      allAccessesChartData: {},
      groupedAccessesChartData: {},
      locationHeaders: [
        { title: 'Location', value: 'location', sortable: true },
        { title: 'ISP', value: 'isp', sortable: true },
        { title: 'IP', value: 'ip', sortable: true },
        { title: 'Count', value: 'count', sortable: true }
      ],
      elementViewedHeaders: [
        { title: 'Element Viewed', value: 'elementViewed', sortable: true },
        { title: 'Count', value: 'count', sortable: true }
      ],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            min: 0,
            max: 20,
            ticks: {
              maxRotation: 90,
              minRotation: 90
            }
          }
        }
      }
    };
  },
  async created() {
    if (!this.$store.state.authToken) {
      this.$router.push({ name: 'Login' });
    }
    
  },
  watch: {
    hoursStep() {
      if(!this.showCustomGroupBy){ 
        this.processDataForChartsByHours();
      }
    },
    expanded() {
      console.log(this.expanded);
    }
  },
  computed: {
    combinedLocationData() {
      return this.locationData.map(item => ({
        ...item,
        location: `${item.country ?? "/"}, ${item.regionName ?? "/"}, ${item.city ?? "/"}`
      }));
    }
  }
};
</script>

<style scoped lang="scss">
@import "../style/_variables.scss";
.accesses-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    flex-flow: column;
    flex-wrap: wrap;
    gap: 5px;
    .v-btn {
      color: $primary-contrast-color;
      background: $primary-gradient;
      &:nth-child(odd) {
        background: $secondary-gradient;
      }
    }
  }
  .filter-form-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;

    border-radius: 5px;

    form {
      padding: 20px;
      .form-input{
        min-width: 350px;
      }
      .form-button-container {
        display: flex;
        justify-content: center;
      }
    }
  }
  .chart-box {
    width: 100%;
    min-width: 350px;
  }
  .data-table-container {
    width: 100%;
    min-width: 350px;
  }
  
  .custom-group-by-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
    border-radius: 5px;
    .custom-group-by-form {
      padding: 20px;
      .select-container {
        display: flex;
        justify-content: center;
        margin-top: 20px;
      }
    }
    .custom-group-by-chart {
      width: 100%;
      min-width: 350px;
    }
    .custom-group-by-table {
      margin-top: 10px;
      width: 100%;
      min-width: 350px;
    }
  }
}
</style>