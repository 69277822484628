export const defaultMeta = {
    title: "cinopedia.cloud",
    description: "Default description of the page.",
    author: "cinopedia.cloud",
    robots: "index,follow",
    keywords: "razze,fci,enci,cani,cinofilia,motivazioni,educatore cinofilo,addestratore,addestramento,addestramento cani,addestramento cinofilo,addestramento positivo,addestramento cani positivo,addestramento cani modena,addestramento cani bologna,addestramento cani reggio emilia,educatore cani modena,educatore cani bologna,educatore cani reggio emilia",
};


export function updateMetaInfo(metaPassed = null) {

    const metaInfo = metaPassed || defaultMeta;

    // Update the title
    document.title = metaInfo.title || defaultMeta.title;

    // Update meta tags
    const metaTags = [
        { name: "description", content: metaInfo.description || defaultMeta.description },
        { name: "author", content: metaInfo.author || defaultMeta.author },
        { name: "robots", content: metaInfo.robots || defaultMeta.robots },
        { name: "keywords", content: metaInfo.keywords || defaultMeta.keywords },
    ];

    metaTags.forEach(({ name, content }) => {
        let tag = document.querySelector(`meta[name="${name}"]`);
        if (!tag) {
            tag = document.createElement("meta");
            tag.setAttribute("name", name);
            document.head.appendChild(tag);
        }
        tag.setAttribute("content", content);
    });
}