import LocalString from "@/entities/LocalString";
import ArticleCategory from "@/entities/ArticleCategory";

export default class Article {
    constructor(data, categories) {
        this.id = data.id;
        this.title = new LocalString(data.title);
        this.slug = new LocalString(data.slug);
        this.content = new LocalString(data.content);
        this.categoryId = data.categoryId;
        this.onlineFrom = data.onlineFrom ? new Date(data.onlineFrom) : null;
        this.onlineTo = data.onlineTo ? new Date(data.onlineTo) : null;
        this.imgUrl = data.imgUrl;
        this.prevArticleLink = new LocalString(data.prevArticleLink);
        this.nextArticleLink = new LocalString(data.nextArticleLink);
        this.abstract = new LocalString(data.abstract);
        


        this.category = data.category 
            ? new ArticleCategory(data.category) 
            : categories 
                ? categories.find(x => x.id === data.categoryId)
                : null;
    }
}