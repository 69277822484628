<template>
  <div v-if="showBanner" class="cookie-banner-container">
    <div v-if="showBanner" class="cookie-banner">
      <h3>{{getTranslation('cookie-consent-subtitle')}}</h3>
      <p>{{getTranslation('cookie-consent')}}</p>
      <p>{{getTranslation('cookie-consent-1')}}</p>
      <div class="cookie-button-container">
        <v-btn
            class="primary-btn"
            @click="acceptAllCookies"
            rounded
            flat
        >{{getTranslation('cookie-consent-consent')}}</v-btn>
        <a
            @click="acceptTechCookies"
        >{{getTranslation('cookie-consent-tech')}}</a>
        
      </div>
    </div>
    
  </div>
</template>

<script>
import {getTranslation} from "@/services/stringTableService";

export default {
  data() {
    return {
    };
  },
  methods: {
    getTranslation(value) {
      return getTranslation('IT', value);
    },
    acceptAllCookies() {
      localStorage.setItem('cookiesAccepted', 'all');
      this.showBanner = false;
      this.$store.dispatch('setGoogleTag', true);
      window.location.reload();
    },
    acceptTechCookies() {
      localStorage.setItem('cookiesAccepted', 'tech');
      this.showBanner = false;
      this.$store.dispatch('setGoogleTag', false);
      window.location.reload();
    },
  },
  computed: {
    showBanner() {
      return this.$store.state.showCookieBanner;
    },
  },
};
</script>

<style scoped lang="scss">
@import '../style/_variables.scss';
.cookie-banner-container {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  .cookie-banner {
    background-color: $secondary-background-color;
    color: $text-color;
    text-align: center;
    padding: 10px;
    .cookie-button-container {
      margin-top: 20px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      a {
        font-family: $header-font;
        text-decoration: underline;
        text-transform: lowercase;
        cursor: pointer;
        color: $text-color;
      }
    }
    border-radius: 5px;
  }
}
</style>