<template>
  <div class="motivational-group-item">
    <h2 class="linkable">
      <router-link :to="`/motivational-classification/${motivationalGroup.id}`" itemprop="url">
        {{ motivationalGroup.name.get(language) }}
      </router-link>
    </h2>
    <p v-if="!!motivationalGroup.description?.get(language)">
      {{ motivationalGroup.description?.get(language)}}
    </p>
    <div class="content">
      <div class="emotional-asset-arousal-container">
        <div v-if="motivationalGroup.arousalLevel" class="emotional-asset-arousal-box">
          <router-link to="/article/6744c928556bde3bc032c7c0/stato-di-attivazione" itemprop="url">
            <h3 class="linkable">{{ getTranslation(language, "arousal") }}</h3>
          </router-link>
          <p>{{ motivationalGroup.arousalLevel?.name?.get(language) }}</p>
        </div>
        <div v-if="motivationalGroup.emotionalAsset?.length > 0" class="emotional-asset-arousal-box">
          <router-link to="/article/6744c916556bde3bc032c7bf/assetto-emozionale" itemprop="url">
            <h3 class="linkable">{{ getTranslation(language, "emotional-asset") }}</h3>
          </router-link>
          <p>{{ motivationalGroup.emotionalAsset?.map(x => x.name?.get(language)).join(' / ') }}</p>
        </div>
      </div>
      <div>
        <router-link to="/article/6744c939556bde3bc032c7c1/motivazioni" itemprop="url">
          <h3 class="linkable">{{ getTranslation(language, "motivations") }}</h3>
        </router-link>
        <div class="motivations-container">
          <div>
            <h3>{{ getTranslation(language, "high") }}</h3>
            <MotivationItem
                v-for="motivation in motivationalGroup.orderedMotivations.filter(x => x.level === 'high')"
                :key="motivation.id"
                :language="language"
                :motivation="motivation"
            />
          </div>
          <div>
            <h3>{{ getTranslation(language, "medium") }}</h3>
            <MotivationItem
                v-for="motivation in motivationalGroup.orderedMotivations.filter(x => x.level === 'medium')"
                :key="motivation.id"
                :language="language"
                :motivation="motivation"
            />
          </div>
          <div>
            <h3>{{ getTranslation(language, "low") }}</h3>
            <MotivationItem
                v-for="motivation in motivationalGroup.orderedMotivations.filter(x => x.level === 'low')"
                :key="motivation.id"
                :language="language"
                :motivation="motivation"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import MotivationalGroup from "@/entities/MotivationalGroup";
import {getTranslation} from "../services/stringTableService";
import MotivationItem from "@/components/MotivationItem.vue";

export default {
  name: "MotivationalGroupItem",
  components: {MotivationItem},
  methods: {
    getTranslation,
  },
  props: {
    motivationalGroup: {
      type: MotivationalGroup,
      required: true,
    },
    language: {
      type: String,
      required: true,
    },
  }
};
</script>
<style scoped lang="scss">
@import "../style/_variables.scss";

.motivational-group-item {
  h1, h2, h3 {
    font-family: $header-font;
    font-weight: bolder;
    text-transform: uppercase;
  }
  .linkable {
    cursor: pointer;
    color: $primary-color;
    a {
      color: $primary-color;
    }
    text-decoration: underline;
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    .emotional-asset-arousal-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-wrap: wrap;
      gap: 1rem;      
      .emotional-asset-arousal-box {
        min-width: 150px;
      }
    }
  }
  .motivations-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    h2 {
      font-family: $header-font;
      font-weight: bolder;
      text-transform: none;
    }
  }
}
</style>