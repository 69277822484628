<template>
  <div class="motivational-group-detail-container">
    <div class="primary-box title-box">
      <h1 style="text-transform: uppercase">{{getTranslation(language, "motivational-groups")}}</h1>
      <h5>{{getTranslation(language, "motivational-group-warning")}}</h5>
    </div>
    <div v-if="groups.length > 0">
<!--      <v-list v-if="!$vuetify.display.mdAndUp" class="list-card">-->
<!--        <v-list-item-->
<!--            v-for="group in groups"-->
<!--            :key="group.id"-->
<!--            class="list-item"-->
<!--            @click="navigateToMotivationalGroupDetail(group.id)"-->
<!--        >-->

<!--          <template v-slot:prepend>-->
<!--            <img src="/img/light-bulb-icon.svg" alt="CM" width="25">-->
<!--          </template>-->
<!--          <v-list-item-title>{{ group.name?.get(language)}}</v-list-item-title>-->
<!--          <v-list-item-subtitle>{{ group.description?.get(language)}}</v-list-item-subtitle>-->
<!--        </v-list-item>-->
<!--      </v-list>-->
      <div class="card-deck">
        <v-card 
            class="card" 
            v-for="group in groups" 
            :key="group.id"
            itemscope itemtype="https://schema.org/NewsArticle"
        >
          <router-link :to="`/motivational-classification/${group.id}`" itemprop="url">
            <v-img src="/img/light-bulb-icon.svg" alt="CM" height="150px"></v-img>
            <div class="card-description">
              <v-card-title itemprop="headline">{{ group.name?.get(language) }}</v-card-title>
              <v-card-subtitle>{{ group.description?.get(language) }}</v-card-subtitle>
            </div>

            <p style="display: none" itemprop="image">/img/paw-print-icon.svg</p>
            <p style="display: none" itemprop="author" itemtype="https://schema.org/Person">
              <span itemprop="name">Cinopedia.cloud</span>
            </p>
          </router-link>
        </v-card>
      </div>
    </div>
    <div v-else>
      <h2>{{getTranslation(language, "to-be-defined")}}</h2>
      <p>{{getTranslation(language, "if-you-have-it")}} <a href="mailto:cinopedia@fiveamtech.it">cinopedia@fiveamtech.it</a></p>
      
    </div>
  </div>
</template>

<script>
import apiService from "@/services/ApiService";
import {getTranslation} from "@/services/stringTableService";
import {updateMetaInfo} from "@/middleware/metaUpdater";

export default {
  methods: {
    getTranslation,
    navigateToMotivationalGroupDetail(id) {
      this.$router.push({ name: "MotivationalGroupDetail", params: { id } });
    },
    async fetchGroupList() {
      try {
        this.$store.dispatch('setLoading', true);
        const groups = await apiService.getMotivationalGroupList();
        this.groups = groups.sort((a, b) => {
          const nameA = a.name?.get(this.language).toUpperCase();
          const nameB = b.name?.get(this.language).toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        
        console.log(groups)
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch('setLoading', false);
      }
    }
  },
  data() {
    return {
      groups: [],
      language: "IT"
    };
  },
  async created() {
    await this.fetchGroupList();
    
    updateMetaInfo({
      title: "Classificazioni motivazionali - Cinopedia.cloud",
      description: "Lista dei gruppi motivazionali - Cinopedia.cloud",
      keywords: "gruppi, motivazionali, cinofilia, cani, cinopedia, motivazioni, classificazione motivazionale",
    })
  }
};
</script>

<style scoped lang="scss">
@import "../style/_variables.scss";

.motivational-group-detail-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  h1, h2 {
    font-family: $header-font;
    font-weight: bolder;
  } 
  
  .motivations-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    h2 {
      font-family: $header-font;
      font-weight: bolder;
    }
  }
  .list-card {
    margin-top: 10px;
    overflow-y: auto;
    max-height: 70vh;
  }
  .list-item {
    text-align: left;
    max-width: 90vw;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    :hover {
      color: $primary-color;
    }
    .v-list-item-title, .v-list-item-subtitle {
      text-overflow: unset !important;
      white-space: normal !important;
    }
  }
}
</style>