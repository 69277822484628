<template>
  <div class="categories-container">
    <div class="title-box primary-box" itemscope itemtype="https://schema.org/NewsArticle">
      <h2 itemprop="headline">{{ category?.title?.get(language)}}</h2>
      <h3>{{ category?.subtitle?.get(language)}}</h3>
      <p style="display: none" itemprop="author" itemtype="https://schema.org/Person">
        <span itemprop="name">Cinopedia.cloud</span>
      </p>
      <p style="display: none" itemprop="image">/img/newsstand.svg</p>
    </div>
    <div class="category-container">
<!--      <div class="card-deck">-->
<!--        <v-card-->
<!--            v-for="article in articles"-->
<!--            :key="article.id"-->
<!--            @click="navigateToArticle(article.id)"-->
<!--            class="card"-->
<!--            itemscope itemtype="https://schema.org/NewsArticle"-->
<!--        >-->
<!--          <router-link :to="`/article/${article.id}/${article.slug.get(language)}`" itemprop="url">-->
<!--            <v-img :src="article.imageUrl" :alt="article.title" height="200px" v-if="article.imgUrl">-->
<!--              <p style="display: none" itemprop="image">{{ article.imageUrl }}</p>-->
<!--            </v-img>-->
<!--            <v-img src="/img/newspaper.svg" :alt="article.title" height="200px" v-else>-->
<!--              <p style="display: none" itemprop="image">/img/newspaper.svg</p>-->
<!--            </v-img>-->
<!--            <div class="card-description">-->
<!--              <v-card-title itemprop="headline">{{ article?.title?.get(language) }}</v-card-title>-->
<!--              <v-card-subtitle>{{ article?.subtitle?.get(language) }}</v-card-subtitle>-->
<!--            </div>-->
<!--            <p style="display: none" itemprop="author" itemtype="https://schema.org/Person">-->
<!--              <span itemprop="name">Cinopedia.cloud</span>-->
<!--            </p>-->
<!--          </router-link>-->
<!--        </v-card>-->
<!--      </div>-->
      <v-expansion-panels variant="popout" class="my-4">
        <v-expansion-panel
            v-for="article in articles"
            :key="article.id"
            :title="article.title.get(language)"
        >
          <v-expansion-panel-text>
            <p class="abstract">{{ article.abstract?.get(language) }}</p>
            <router-link :to="`/article/${article.id}/${article.slug.get(language)}`" class="read-more-link">
              {{ getTranslation(language, "article-read-more") }}
            </router-link>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>
<script>
import apiService from "@/services/ApiService";
import {getTranslation} from "@/services/stringTableService";
import {updateMetaInfo} from "@/middleware/metaUpdater";

export default {
  data() {
    return {
      category: {},
      articles: [],
      language: "IT"
    };
  },
  methods: {
    getTranslation,
    navigateToArticle(id) {
      this.$router.push({ name: "ArticleDetail", params: { id } });
    },
    async fetchArticles() {
      try {
        this.$store.dispatch('setLoading', true);
        const categoryId = this.$route.params.id;
        const category = await apiService.getArticleCategory(categoryId);
        this.category = category;
        this.articles = category.articles;        
        this.updateUrlWithSlug(category);
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch('setLoading', false);
      }
    },
    updateUrlWithSlug(data) {
      this.$router.replace({
        name: 'ArticleCategoryDetail',
        params: { id: data.id, slug: data.slug.get(this.language) },
      });
    }
  },
  watch: {
    $route(to, from) {
      if(to.params.id !== from.params.id) {
        this.fetchArticles();
      }
    },
    category() {
      updateMetaInfo(this.metaInfo);
    }
  },
  computed: {
    metaInfo() {
      return {
        title: `${this.category?.title?.get(this.language)} - Cinopedia.cloud`,
        description: `Articoli sulla categoria ${this.category?.title?.get(this.language)}`,
        keywords: `razza,razze,fci,enci,cani,cinofilia,articoli,${this.category?.title?.get(this.language)}`,
      }
    }
  },
  async created() {
    await this.fetchArticles();
  }
};
</script>
<style scoped lang="scss">
@import "../style/_variables.scss";

.abstract {
  margin-bottom: 10px;
  text-align: left;
}
.read-more-link {
  color: $primary-color;
  text-decoration: underline;
  font-weight: bold;
  margin-top: 10px;
  display: inline-block;
}
.categories-container {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

h2, h3, h4, p {
  font-family: $header-font;
}
</style>