export default class LocalString {
  constructor(data) {
    this.values = data;
  }

  get(language) {
    if(this.values && language in this.values)
      return this.values[language];
    
    return null;
  }

  set(language, value) {
    this.values[language] = value;
  }
}